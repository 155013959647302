import { toggleClasses } from "./toggle-classes";

// Controls the display of the advertiser disclosure
export const toggleAdvertiserDisclosure = () => {
	document
		.querySelectorAll(".advertiserDisclosureToggle")
		?.forEach((advertiserDisclosureToggle) => {
			advertiserDisclosureToggle.addEventListener("click", () => {
				toggleClasses(document.querySelector(".advertiserDisclosureWindow"), [
					"tw-hidden",
					"tw-flex",
				]);
			});
		});
};

import { toggleAdvertiserDisclosure } from "../../../bladejs/toggle-advertiser-disclosure";

document.addEventListener("DOMContentLoaded", () => {
	// Controls the display of the advertiser disclosure
	toggleAdvertiserDisclosure();

	function isInParentViewport(element) {
		const { top, bottom, left, right } = element.getBoundingClientRect();
		const {
			top: parentTop,
			bottom: parentBottom,
			left: parentLeft,
			right: parentRight,
		} = element.parentNode.getBoundingClientRect();

		return (
			top >= parentTop &&
			bottom <= parentBottom &&
			left >= parentLeft &&
			right <= parentRight
		);
	}

	// Controls the scrolling behavior of the tab navigation
	const activeTabNavigationItem = document.querySelector(
		".tab-navigation__item--active",
	);

	if (activeTabNavigationItem && !isInParentViewport(activeTabNavigationItem)) {
		activeTabNavigationItem.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "start",
		});
	}
});
